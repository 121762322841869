<script lang="ts" setup>
import type { Status } from "~/types";

const { status } = defineProps<{
  status?: Status;
  class?: string;
}>();
const activeLinks = computed(() => {
  switch (status) {
    case "accepted":
      return [
        { title: "mainnav.claims.title", to: "/claims" },
        { title: "mainnav.claims.recent", to: "/claims/accepted" }
      ];
    case "discarded":
      return [
        { title: "mainnav.claims.title", to: "/claims" },
        { title: "mainnav.claims.discarded", to: "/claims/discarded" }
      ];
    case "checked":
      return [
        { title: "mainnav.claims.title", to: "/claims" },
        { title: "mainnav.claims.completed", to: "/claims/completed" }
      ];

    case "submitted":
      return [
        { title: "mainnav.submissions.title", to: "/submissions" },
        { title: "mainnav.submissions.inbox", to: "/submissions/inbox" }
      ];
    case "observed":
      return [
        { title: "mainnav.submissions.title", to: "/submissions" },
        { title: "mainnav.submissions.observed", to: "/submissions/observed" }
      ];
    case "spam":
      return [
        { title: "mainnav.submissions.title", to: "/submissions" },
        { title: "mainnav.submissions.spam", to: "/submissions/spam" }
      ];
    case "rejected":
      return [
        { title: "mainnav.submissions.title", to: "/submissions" },
        { title: "mainnav.submissions.rejected", to: "/submissions/rejected" }
      ];
    case "delete":
      return [
        { title: "mainnav.submissions.title", to: "/submissions" },
        { title: "mainnav.submissions.deleted", to: "/submissions/deleted" }
      ];

    default:
      return [];
  }
});
</script>

<template>
  <nav :class="cn($props.class)">
    <ul class="flex gap-1 items-center">
      <li
        v-for="link in activeLinks"
        :key="link.to"
        class="after:content-['/'] after:ml-1 last:after:content-none"
      >
        <NuxtLink :to="link.to" class="text-xs xs:text-sm hover:text-color-8">{{ $t(link.title) }}</NuxtLink>
      </li>
    </ul>
  </nav>
</template>
